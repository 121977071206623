import axios from 'axios';

const getAuthAxiosInstance = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_BASE_URI,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('_auth_'))}`,
    },
  });
};

export default getAuthAxiosInstance;
