import { useState } from 'react';
import { useAuthContext } from './useAuthContext';
import getAuthAxiosInstance from '../utils/axios';

const axios = getAuthAxiosInstance();

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { dispatch } = useAuthContext();

  const login = async (loginValue, navigate, where) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post('/auth/login', loginValue);
      console.log(response);
      const { data } = response;

      if (!data.success) {
        setIsLoading(false);
        setError(data.message);
      }
      if (data.success) {
        // save the user to local storage
        localStorage.setItem('user', JSON.stringify(data.user));
        localStorage.setItem('_auth_', JSON.stringify(data.authToken));

        // update the auth context
        dispatch({ type: 'LOGIN', payload: data.user });

        setTimeout(() => {
          // update loading state
          setIsLoading(false);
        }, 2000);
      }
    } catch (e) {
      console.log(e);
      //   setError(e);
      setIsLoading(false);
    }
  };

  return { login, isLoading, error };
};
