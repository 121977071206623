import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import Label from '../../../components/label/Label';
import getAuthAxiosInstance from '../../../utils/axios';
import Iconify from '../../../components/iconify';

export default function ViewDepartment({ onUpdateClick, refresh }) {
  const axios = getAuthAxiosInstance();
  const { enqueueSnackbar } = useSnackbar();
  const [department, setDepartment] = useState([]);

  const fetchDepartment = async () => {
    try {
      const response = await axios.get('/department');
      const { data } = response;
      if (data.success) {
        setDepartment(data.department);
      } else {
        enqueueSnackbar(data.message || 'Something went wrong', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, [refresh]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>S.N</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {department &&
              department.map((row, index) => (
                <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>
                    <Label color={row.isActive ? 'success' : 'error'}>{row.isActive ? 'Active' : 'Hidden'}</Label>
                  </TableCell>
                  <TableCell align="center">
                    <MenuItem
                      onClick={() => {
                        onUpdateClick(row);
                      }}
                    >
                      <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                      Edit
                    </MenuItem>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
