import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

const PrivateRoute = ({ isAuth, children }) => {
  const prevLocation = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth) {
      navigate(`/login?redirectTo=${prevLocation.pathname}`, {
        replace: true,
      });
    }
  }, [isAuth]);

  return isAuth ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
