import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Card, Stack, Button, Container, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import ViewCourse from '../sections/@dashboard/course/ViewCourse';
import AddCourseModal from '../sections/@dashboard/course/AddCourseModal';
import Iconify from '../components/iconify';
import getAuthAxiosInstance from '../utils/axios';
import CourseContentModal from 'src/sections/@dashboard/course/CourseContentModal';
import { useEffect } from 'react';

export default function CoursePage() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [oldCourse, setOldCourse] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [courseContentDialogOpen, setCourseContentDialogOpen] = useState(false);
  const axios = getAuthAxiosInstance();

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const onDeleteClick = async (course) => {
    try {
      const response = await axios.delete(`/course/delete/${course._id}`);
      const { data } = response;
      if (data.success) {
        enqueueSnackbar('Deleted Successfully');
        setRefresh((prev) => !prev);
      } else {
        enqueueSnackbar(data.message || 'Something went wrong', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const onUpdateClick = (course) => {
    setOldCourse(course);
    setIsEdit(true);
    setDialogOpen(true);
  };

  const onCourseContentClick = (course) => {
    setCourseContentDialogOpen(true);
    setOldCourse(course);
  };

  return (
    <>
      <Helmet>
        <title>Course</title>
      </Helmet>

      <AddCourseModal
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        isEdit={isEdit}
        oldCourse={oldCourse}
        setRefresh={setRefresh}
      />

      <CourseContentModal
        courseContentDialogOpen={courseContentDialogOpen}
        setCourseContentDialogOpen={setCourseContentDialogOpen}
        oldCourse={oldCourse}
        setRefresh={setRefresh}
      />

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Course
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setIsEdit(false);
              handleDialogOpen();
            }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Course
          </Button>
        </Stack>

        <Card>
          <ViewCourse
            onUpdateClick={onUpdateClick}
            refresh={refresh}
            onDeleteClick={onDeleteClick}
            onCourseContentClick={onCourseContentClick}
            setRefresh={setRefresh}
          />
        </Card>
      </Container>
    </>
  );
}
