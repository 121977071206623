import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Dialog, DialogTitle, DialogContent, Grid, TextField, FormControlLabel, Switch } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import getAuthAxiosInstance from '../../../utils/axios';
import ContentEditor from '../../../components/content_editor/ContentEditor';

const addPopValidationSchema = yup.object({
  isActive: yup.boolean('Is Active'),
  title: yup.string('Title'),
});

const AddDepartmentModal = ({ dialogOpen, setDialogOpen, isEdit, oldDepartment, setRefresh }) => {
  const axios = getAuthAxiosInstance();
  const { enqueueSnackbar } = useSnackbar();
  const [contentText, setContentText] = useState('');

  const handleClose = () => {
    setDialogOpen(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: isEdit && oldDepartment?.title ? oldDepartment.title : '',
      isActive: isEdit && oldDepartment?.isActive ? oldDepartment.isActive : false,
    },
    validationSchema: addPopValidationSchema,
    onSubmit: (values) => {
      if (isEdit) {
        updateOldDepartment(values);
      } else {
        addDepartment(values);
      }
    },
  });

  const handleContentEditor = (text) => {
    setContentText(text);
  };

  useEffect(() => {
    if (isEdit && oldDepartment) {
      setContentText(oldDepartment.content);
    }
  }, [isEdit, oldDepartment]);

  const addDepartment = async (values) => {
    try {
      let departmentData = {
        title: values.title,
        isActive: values.isActive,
        content: contentText,
      };

      const response = await axios.post(`/department/add`, departmentData);
      const { data } = response;
      if (data.success) {
        formik.resetForm();
        setRefresh((prev) => !prev);
        handleClose();
        enqueueSnackbar('Department Added Successfully');
      } else {
        enqueueSnackbar(data.message || 'Error Adding', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const updateOldDepartment = async (values) => {
    let departmentData = {
      title: values.title,
      slug: oldDepartment.slug,
      id: oldDepartment._id,
      isActive: values.isActive,
      content: contentText,
    };

    try {
      const response = await axios.post(`/department/update/`, departmentData);
      const { data } = response;
      if (data.success) {
        formik.resetForm();
        setRefresh((prev) => !prev);
        handleClose();
        enqueueSnackbar('Department Updated Successfully');
      } else {
        enqueueSnackbar(data.message || 'Error Adding', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  return (
    <Dialog open={dialogOpen} onClose={handleClose} maxWidth="lg">
      <DialogTitle>{isEdit ? 'Update Department' : 'Add Department'}</DialogTitle>

      <DialogContent>
        <br />
        <br />
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <TextField
                fullWidth
                id="title"
                name="title"
                label="Title"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
            </Grid>
            <Grid item sm={12}>
              <ContentEditor model={contentText} handleModelChange={handleContentEditor} />
            </Grid>

            <Grid item sm={12}>
              <FormControlLabel
                control={
                  <Switch
                    name="isActive"
                    checked={formik.values.isActive}
                    onChange={formik.handleChange}
                    error={formik.touched.isActive && Boolean(formik.errors.isActive)}
                    helperText={formik.touched.isActive && formik.errors.isActive}
                  />
                }
                label="Active"
              />
            </Grid>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 2, ml: 3 }}>
              {isEdit ? 'Update Department' : 'Add Department'}
            </LoadingButton>
          </Grid>
        </form>
        <br />
      </DialogContent>
    </Dialog>
  );
};

export default AddDepartmentModal;
