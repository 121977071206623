import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import getAuthAxiosInstance from '../../../utils/axios';

const addPopValidationSchema = yup.object({
  isActive: yup.boolean('Is Active'),
  title: yup.string('Title'),
});

const AddGalleryModal = ({ dialogOpen, setDialogOpen, isEdit, oldGallery, setRefresh }) => {
  const axios = getAuthAxiosInstance();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedImages, setSelectedImages] = useState([]);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const onImageChange = (e) => {
    setSelectedImages(e.target.files);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: isEdit && oldGallery?.title ? oldGallery.title : '',
      isActive: isEdit && oldGallery?.isActive ? oldGallery.isActive : false,
    },
    validationSchema: addPopValidationSchema,
    onSubmit: (values) => {
      if (isEdit) {
        updateOldGallery(values);
      } else {
        addDegree(values);
      }
    },
  });

  const addDegree = async (values) => {
    const formData = new FormData();
    formData.append('isActive', values.isActive);
    formData.append('title', values.title);
    if (selectedImages.length != 0) {
      for (const single_file of selectedImages) {
        formData.append('media', single_file);
      }
    }
    try {
      const response = await axios.post(`/gallery/add`, formData);
      const { data } = response;
      if (data.success) {
        formik.resetForm();
        setRefresh((prev) => !prev);
        setSelectedImages(null);
        handleClose();
        enqueueSnackbar('Gallery Added Successfully');
      } else {
        enqueueSnackbar(data.message || 'Error Adding', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const updateOldGallery = async (values) => {
    const formData = new FormData();

    formData.append('isActive', values.isActive);
    formData.append('title', values.title);
    formData.append('slug', oldGallery.slug);
    formData.append('_id', oldGallery._id);
    if (selectedImages && selectedImages.length != 0) {
      for (const single_file of selectedImages) {
        formData.append('media', single_file);
      }
    }
    try {
      const response = await axios.post(`/gallery/add/`, formData);
      const { data } = response;
      if (data.success) {
        formik.resetForm();
        setRefresh((prev) => !prev);
        setSelectedImages(null);
        handleClose();
        enqueueSnackbar('Gallery Updated Successfully');
      } else {
        enqueueSnackbar(data.message || 'Error Adding', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  return (
    <Dialog open={dialogOpen} onClose={handleClose} maxWidth="lg">
      <DialogTitle>{isEdit ? 'Update Gallery' : 'Add Gallery'}</DialogTitle>

      <DialogContent>
        <br />
        <br />
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <TextField
                fullWidth
                id="title"
                name="title"
                label="Title"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
            </Grid>

            <Grid item sm={12}>
              <FormControlLabel
                control={
                  <Switch
                    name="isActive"
                    checked={formik.values.isActive}
                    onChange={formik.handleChange}
                    error={formik.touched.isActive && Boolean(formik.errors.isActive)}
                    helperText={formik.touched.isActive && formik.errors.isActive}
                  />
                }
                label="Active"
              />
            </Grid>
            <Grid item sm={12}>
              <Button variant="contained" component="label">
                Upload
                <input
                  hidden
                  name="photos"
                  type="file"
                  multiple
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={onImageChange}
                  className="form-control"
                />
              </Button>
            </Grid>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 2, ml: 3 }}>
              {isEdit ? 'Update Gallery' : 'Add Gallery'}
            </LoadingButton>
          </Grid>
        </form>
        <br />
      </DialogContent>
    </Dialog>
  );
};

export default AddGalleryModal;
