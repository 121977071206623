import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Dialog, Button, AppBar, Toolbar, IconButton, Typography, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import DeleteIcon from '@mui/icons-material/Delete';

import FolderIcon from '@mui/icons-material/Folder';
import { useSnackbar } from 'notistack';
import getAuthAxiosInstance from '../../../utils/axios';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GalleryImageModal = ({ imageViewDialogOpen, setImageViewDialogOpen, oldGallery }) => {
  const axios = getAuthAxiosInstance();
  const { enqueueSnackbar } = useSnackbar();
  const [gallery, setGallery] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const getOneGallery = async () => {
    try {
      const response = await axios.get(`/gallery/${oldGallery._id}`);
      const { data } = response;
      if (data.success) {
        setGallery(data.gallery);
      } else {
        enqueueSnackbar(data.message || 'Cannot get  gallery', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Error getting gallery', { variant: 'error' });
    }
  };

  const handleClose = () => {
    setImageViewDialogOpen(false);
  };

  const handleOnDeleteClick = (img) => {
    setSelectedImage(img);
    setDeleteConfirmOpen(true);
  };

  useEffect(() => {
    if (oldGallery) {
      getOneGallery();
    }
  }, [oldGallery, refresh]);

  return (
    <Dialog fullScreen open={imageViewDialogOpen} onClose={handleClose} TransitionComponent={Transition}>
      <AlertDialog
        deleteConfirmOpen={deleteConfirmOpen}
        setDeleteConfirmOpen={setDeleteConfirmOpen}
        selectedImage={selectedImage}
        enqueueSnackbar={enqueueSnackbar}
        oldGallery={oldGallery}
        setRefresh={setRefresh}
      />
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {oldGallery?.title}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleClose}>
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
        {gallery &&
          gallery.media.map((item) => (
            <ImageListItem key={item}>
              <img
                src={`${process.env.REACT_APP_BASE}${item}?w=164&h=164&fit=crop&auto=format`}
                srcSet={`${process.env.REACT_APP_BASE}${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                alt={item}
                loading="lazy"
              />
              <ImageListItemBar
                sx={{
                  background:
                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' + 'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                }}
                // title={item}
                position="bottom"
                actionIcon={
                  <IconButton
                    sx={{ color: 'red' }}
                    aria-label={`star ${item}`}
                    onClick={() => handleOnDeleteClick(item)}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
                actionPosition="right"
              />
            </ImageListItem>
          ))}
      </ImageList>
    </Dialog>
  );
};

export default GalleryImageModal;

function AlertDialog({
  deleteConfirmOpen,
  setDeleteConfirmOpen,
  selectedImage,
  enqueueSnackbar,
  oldGallery,
  setRefresh,
}) {
  const handleClose = () => {
    setDeleteConfirmOpen(false);
  };

  const axios = getAuthAxiosInstance();
  const handleOnDeleteClick = async () => {
    try {
      const response = await axios.post(`/gallery/delete/${oldGallery?._id}`, {
        imageUrl: selectedImage,
      });
      const { data } = response;
      if (data.success) {
        setRefresh((prev) => !prev);
        setDeleteConfirmOpen(false);
        enqueueSnackbar('Deleted Successfully');
      } else {
        enqueueSnackbar(data.message || 'Something went wrong', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  return (
    <div>
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to delete?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this image?
          </DialogContentText>
          <img
            src={`${process.env.REACT_APP_BASE}${selectedImage}`}
            srcSet={`${process.env.REACT_APP_BASE}${selectedImage}`}
            width="200"
            height="200"
            alt={selectedImage}
            loading="lazy"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleOnDeleteClick} autoFocus>
            Yes Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
