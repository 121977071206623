import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper, Popover, MenuItem, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import Label from '../../../components/label/Label';
import getAuthAxiosInstance from '../../../utils/axios';
import Iconify from '../../../components/iconify';
import handleDeleteClick from 'src/components/DeleteModal';

export default function ViewBOD({ onUpdateClick, refresh, onDeleteClick }) {
  const [open, setOpen] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [bod, setBod] = useState([]);
  const axios = getAuthAxiosInstance();
  const fetchBOD = async () => {
    try {
      const response = await axios.get('/bod');
      const { data } = response;
      if (data.success) {
        setBod(data.bod);
      } else {
        enqueueSnackbar(data.message || 'Something went wrong', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchBOD();
  }, [refresh]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>S.N</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bod.map((row, index) => (
              <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>
                  <img
                    height={100}
                    width={100}
                    alt={row.shortTitle}
                    src={`${process.env.REACT_APP_BASE}${row.imageUrl}`}
                  />
                </TableCell>
                <TableCell>{row.shortTitle}</TableCell>
                <TableCell>
                  <Label color={row.isActive ? 'success' : 'error'}>{row.isActive ? 'Active' : 'Hidden'}</Label>
                </TableCell>
                <TableCell>
                  <MenuItem
                    onClick={() => {
                      onUpdateClick(row);
                    }}
                  >
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                  </MenuItem>

                  <MenuItem
                    sx={{ color: 'error.main' }}
                    onClick={() => {
                      handleDeleteClick(row, onDeleteClick);
                    }}
                  >
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                  </MenuItem>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
