import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Card, Stack, Button, Container, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import ViewDownloads from '../sections/@dashboard/downloads/ViewDownloads';
import AddDownloadsModal from '../sections/@dashboard/downloads/AddDownloadsModal';
import Iconify from '../components/iconify';
import getAuthAxiosInstance from '../utils/axios';

export default function DownloadsPage() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [oldDownload, setOldDownload] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const axios = getAuthAxiosInstance();

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const onDeleteClick = async (course) => {
    try {
      const response = await axios.delete(`/downloads/delete/${course._id}`);
      const { data } = response;
      if (data.success) {
        enqueueSnackbar('Deleted Successfully');
        setRefresh((prev) => !prev);
      } else {
        enqueueSnackbar(data.message || 'Something went wrong', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const onUpdateClick = (course) => {
    setOldDownload(course);
    setIsEdit(true);
    setDialogOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>Downloads</title>
      </Helmet>

      <AddDownloadsModal
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        isEdit={isEdit}
        oldDownload={oldDownload}
        setRefresh={setRefresh}
      />

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Downloads
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setIsEdit(false);
              handleDialogOpen();
            }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Download
          </Button>
        </Stack>

        <Card>
          <ViewDownloads
            onUpdateClick={onUpdateClick}
            refresh={refresh}
            onDeleteClick={onDeleteClick}
            setRefresh={setRefresh}
          />
        </Card>
      </Container>
    </>
  );
}
