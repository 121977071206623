import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
// @mui
import { Card, Stack, Button, Container, Typography, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useSnackbar } from 'notistack';
import getAuthAxiosInstance from '../../../utils/axios';

const generalInformationValidation = yup.object({
  contact: yup.string('Enter Contact'),
  address: yup.string('Enter your address'),
  email: yup.string('Enter your email'),
  experience: yup.string('Enter your Experience'),
  footerText: yup.string('Enter your Footer'),
  homepageText: yup.string('Enter your Homepage'),
  copyrightText: yup.string('Enter your Copyright'),
  businessHours: yup.string('Enter your Business Hours'),
  contactUsText: yup.string('Enter your Contact Us'),
});

export default function GeneralInformation() {
  const axios = getAuthAxiosInstance();
  const [refresh, setRefresh] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [generalInformation, setGeneralInformation] = useState([]);

  const getGeneralInfo = async () => {
    try {
      const response = await axios.get('/information/general');
      const { data } = response;
      if (data.success) {
        setGeneralInformation(data.info);
      } else {
        enqueueSnackbar(data.message || 'Something went wrong', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const addGeneralInfo = async (dataObject) => {
    try {
      const response = await axios.post('/information/add', dataObject);
      const { data } = response;
      if (data.success) {
        enqueueSnackbar(data.message || 'Saved Successfully');
        setRefresh((prev) => !prev);
      } else {
        enqueueSnackbar(data.message || 'Something went wrong', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      contact: generalInformation && generalInformation.contact ? generalInformation.contact : '',
      address: generalInformation && generalInformation.address ? generalInformation.address : '',
      email: generalInformation && generalInformation.email ? generalInformation.email : '',
      experience: generalInformation && generalInformation.experience ? generalInformation.experience : '',
      footerText: generalInformation && generalInformation.footerText ? generalInformation.footerText : '',
      homepageText: generalInformation && generalInformation.homepageText ? generalInformation.homepageText : '',
      copyrightText: generalInformation && generalInformation.copyrightText ? generalInformation.copyrightText : '',
      businessHours: generalInformation && generalInformation.businessHours ? generalInformation.businessHours : '',
      contactUsText: generalInformation && generalInformation.contactUsText ? generalInformation.contactUsText : '',
    },
    validationSchema: generalInformationValidation,
    onSubmit: (values) => {
      values.type = 'general';
      addGeneralInfo(values);
    },
  });

  useEffect(() => {
    getGeneralInfo();
  }, [refresh]);

  return (
    <Container>
      <h3>General Information</h3>
      <form onSubmit={formik.handleSubmit}>
        <Grid sx={{ my: '1rem' }} container spacing={3}>
          <Grid item sm={6}>
            <TextField
              fullWidth
              id="contact"
              name="contact"
              label="Contact"
              value={formik.values.contact}
              onChange={formik.handleChange}
              error={formik.touched.contact && Boolean(formik.errors.contact)}
              helperText={formik.touched.contact && formik.errors.contact}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              fullWidth
              id="address"
              name="address"
              label="Address"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              fullWidth
              id="experience"
              name="experience"
              label="Experience"
              value={formik.values.experience}
              onChange={formik.handleChange}
              error={formik.touched.experience && Boolean(formik.errors.experience)}
              helperText={formik.touched.experience && formik.errors.experience}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              fullWidth
              id="footerText"
              name="footerText"
              label="Footer Text"
              value={formik.values.footerText}
              onChange={formik.handleChange}
              error={formik.touched.footerText && Boolean(formik.errors.footerText)}
              helperText={formik.touched.footerText && formik.errors.footerText}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              fullWidth
              id="homepageText"
              name="homepageText"
              label="Home Page Text"
              value={formik.values.homepageText}
              onChange={formik.handleChange}
              error={formik.touched.homepageText && Boolean(formik.errors.homepageText)}
              helperText={formik.touched.homepageText && formik.errors.homepageText}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              fullWidth
              id="copyrightText"
              name="copyrightText"
              label="Copyright Text"
              value={formik.values.copyrightText}
              onChange={formik.handleChange}
              error={formik.touched.copyrightText && Boolean(formik.errors.copyrightText)}
              helperText={formik.touched.copyrightText && formik.errors.copyrightText}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              fullWidth
              id="businessHours"
              name="businessHours"
              label="Business Hours"
              value={formik.values.businessHours}
              onChange={formik.handleChange}
              error={formik.touched.businessHours && Boolean(formik.errors.businessHours)}
              helperText={formik.touched.businessHours && formik.errors.businessHours}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              fullWidth
              id="contactUsText"
              name="contactUsText"
              label="Contact Us Text"
              value={formik.values.contactUsText}
              onChange={formik.handleChange}
              error={formik.touched.contactUsText && Boolean(formik.errors.contactUsText)}
              helperText={formik.touched.contactUsText && formik.errors.contactUsText}
            />
          </Grid>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 2, ml: 3 }}>
            Save
          </LoadingButton>
        </Grid>
      </form>
    </Container>
  );
}
