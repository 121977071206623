// component
import SvgColor from '../../../components/svg-color';
import {
  School,
  Class,
  PeopleAlt,
  RecordVoiceOver,
  RateReview,
  Message,
  Web,
  FileDownload,
  Info,
  MoveUp,
  Collections,
  HomeWork,
  RecordVoiceOverSharp,
} from '@mui/icons-material';

// ----------------------------------------------------------------------

// const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: icon('ic_analytics'),
  // },
  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: icon('ic_user'),
  // },
  {
    title: 'Degree',
    path: '/degree',
    icon: <School />,
  },
  {
    title: 'Course',
    path: '/course',
    icon: <Class />,
  },
  {
    title: 'Bod',
    path: '/bod',
    icon: <PeopleAlt />,
  },
  {
    title: 'Advisor',
    path: '/advisor',
    icon: <RecordVoiceOver />,
  },
  {
    title: 'Testimonial',
    path: '/testimonial',
    icon: <RateReview />,
  },
  {
    title: 'Message',
    path: '/messageboard',
    icon: <Message />,
  },
  {
    title: 'Page',
    path: '/pages',
    icon: <Web />,
  },
  {
    title: 'Download',
    path: '/downloads',
    icon: <FileDownload />,
  },
  {
    title: 'Information',
    path: '/information',
    icon: <Info />,
  },
  {
    title: 'Popup',
    path: '/popup',
    icon: <MoveUp />,
  },
  {
    title: 'Gallery',
    path: '/gallery',
    icon: <Collections />,
  },
  {
    title: 'Department',
    path: '/department',
    icon: <HomeWork />,
  },
  {
    title: 'Faculty',
    path: '/faculty',
    icon: <RecordVoiceOverSharp />,
  },
  {
    title: 'Administration Department',
    path: '/administration-department',
    icon: <RecordVoiceOverSharp />,
  },

  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
