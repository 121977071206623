import { createContext, useReducer, useEffect } from 'react';
import getAuthAxiosInstance from '../utils/axios';

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { user: action.payload, ready: true };
    case 'LOGOUT':
      return { user: null, ready: true };
    default:
      return { ...state, ready: true };
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    ready: false,
  });
  const axios = getAuthAxiosInstance();

  const validateToken = async () => {
    try {
      if (localStorage.getItem('_auth_')) {
        const response = await axios.post('/auth/validate-token', {});
        const { data } = response;
        if (data.success) {
          localStorage.setItem('user', data.user);
          dispatch({ type: 'LOGIN', payload: data.user });
        } else {
          localStorage.removeItem('user');
          localStorage.removeItem('_auth_');
          dispatch({ type: 'LOGOUT' });
        }
      } else {
        dispatch({ type: 'JUST' });
      }
    } catch (e) {
      localStorage.removeItem('user');
      localStorage.removeItem('_auth_');
      dispatch({ type: 'LOGOUT' });
    }
  };

  useEffect(() => {
    // const user = JSON.parse(localStorage.getItem('user'));
    validateToken();

    // if (user) {
    // dispatch({ type: 'LOGIN', payload: user });
    // }
  }, []);

  return <AuthContext.Provider value={{ ...state, dispatch }}>{children}</AuthContext.Provider>;
};
