import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import getAuthAxiosInstance from '../../../utils/axios';
import ContentEditor from '../../../components/content_editor/ContentEditor';

const addPageValidationSchema = yup.object({
  pageName: yup.string('Enter  page name').required('Page Name is required'),
  title: yup.string('Enter title').required('title is required'),
  content: yup.string('Enter your content'),
  isActive: yup.boolean('Is Active'),
});

const AddPagesModal = ({ dialogOpen, setDialogOpen, isEdit, oldPage, setRefresh }) => {
  const axios = getAuthAxiosInstance();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedImage, setSelectedImage] = useState(null);
  const [tempImage, setTempImage] = useState(null);
  const [contentText, setContentText] = useState('');

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleContentEditor = (text) => {
    setContentText(text);
  };

  const onImageChange = (e) => {
    const gSelectedImage = e.target.files[0];
    setSelectedImage(gSelectedImage);
    setTempImage(URL.createObjectURL(gSelectedImage));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      pageName: isEdit && oldPage?.pageName ? oldPage.pageName : '',
      title: isEdit && oldPage?.title ? oldPage.title : '',
      content: isEdit && oldPage?.content ? oldPage.content : '',
      isActive: isEdit && oldPage?.isActive ? oldPage.isActive : false,
    },
    validationSchema: addPageValidationSchema,
    onSubmit: (values) => {
      values.content = contentText;
      if (isEdit) {
        updateOldDegree(values);
      } else {
        addDegree(values);
      }
    },
  });

  useEffect(() => {
    if (isEdit && oldPage) {
      setTempImage(`${process.env.REACT_APP_BASE}${oldPage.imageUrl}`);
      setContentText(oldPage.content);
    } else {
      setTempImage(null);
    }
  }, [isEdit, oldPage]);

  const addDegree = async (values) => {
    if (selectedImage === null) {
      enqueueSnackbar('Select Image', { variant: 'error' });
    } else {
      const formData = new FormData();
      formData.append('isActive', values.isActive);
      formData.append('pageName', values.pageName);
      formData.append('title', values.title);
      formData.append('content', values.content);
      formData.append('imageLocationType', 'pages');
      formData.append('picture', selectedImage);
      try {
        const response = await axios.post(`/page/add`, formData);
        const { data } = response;
        if (data.success) {
          formik.resetForm();
          setRefresh((prev) => !prev);
          setSelectedImage(null);
          setTempImage(null);
          handleClose();
          enqueueSnackbar('Page Added Successfully');
        } else {
          enqueueSnackbar(data.message || 'Error Adding', { variant: 'error' });
        }
      } catch (e) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    }
  };

  const updateOldDegree = async (values) => {
    const formData = new FormData();

    formData.append('isActive', values.isActive);
    formData.append('pageName', values.pageName);
    formData.append('title', values.title);
    formData.append('content', values.content);
    formData.append('imageLocationType', 'pages');
    formData.append('imageUrl', oldPage.imageUrl);
    if (selectedImage) {
      formData.append('picture', selectedImage);
    }
    try {
      const response = await axios.put(`/page/update/${oldPage._id}`, formData);
      const { data } = response;
      if (data.success) {
        formik.resetForm();
        setRefresh((prev) => !prev);
        setSelectedImage(null);
        setTempImage(null);
        handleClose();
        enqueueSnackbar('Page Updated Successfully');
      } else {
        enqueueSnackbar(data.message || 'Error Adding', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  return (
    <Dialog open={dialogOpen} onClose={handleClose} maxWidth="lg">
      <DialogTitle>{isEdit ? 'Update Page' : 'Add Page'}</DialogTitle>

      <DialogContent>
        <br />
        <br />
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <TextField
                fullWidth
                id="pageName"
                name="pageName"
                label="Page Name"
                value={formik.values.pageName}
                onChange={formik.handleChange}
                error={formik.touched.pageName && Boolean(formik.errors.pageName)}
                helperText={formik.touched.pageName && formik.errors.pageName}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                fullWidth
                id="title"
                name="title"
                label="Page Title"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
            </Grid>
            <Grid item sm={12}>
              <ContentEditor model={contentText} handleModelChange={handleContentEditor} />
            </Grid>

            <Grid item sm={12}>
              <FormControlLabel
                control={
                  <Switch
                    name="isActive"
                    checked={formik.values.isActive}
                    onChange={formik.handleChange}
                    error={formik.touched.isActive && Boolean(formik.errors.isActive)}
                    helperText={formik.touched.isActive && formik.errors.isActive}
                  />
                }
                label="Active"
              />
            </Grid>
            <Grid item sm={12}>
              <Button variant="contained" component="label">
                Upload
                <input hidden accept="image/*" type="file" onChange={onImageChange} />
              </Button>
              {tempImage && (
                <img
                  alt="degree"
                  src={tempImage}
                  style={{
                    marginTop: '15px',
                    borderRadius: '20px',
                    width: '200px',
                    height: '200px',
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                />
              )}
            </Grid>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 2, ml: 3 }}>
              {isEdit ? 'Update Page' : 'Add Page'}
            </LoadingButton>
          </Grid>
        </form>
        <br />
      </DialogContent>
    </Dialog>
  );
};

export default AddPagesModal;
