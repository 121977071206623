import { Button } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert'; // Import

const handleDeleteClick = (row, onDeleteClick) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return <DeleteModal onClose={onClose} onDeleteClick={() => onDeleteClick(row)} />;
    },
  });
};

const DeleteModal = ({ onClose, onDeleteClick }) => {
  return (
    <div className="card confirmation-box">
      <p>Are you sure You want to delete?</p>
      <Button onClick={onClose}>No</Button>
      <Button
        color="error"
        variant="contained"
        onClick={() => {
          onDeleteClick();
          onClose();
        }}
      >
        Yes, Delete it!
      </Button>
    </div>
  );
};

export default handleDeleteClick;
