import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  Button,
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  List,
} from '@mui/material';

import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';
import getAuthAxiosInstance from '../../../utils/axios';

const CourseContentModal = ({ courseContentDialogOpen, setCourseContentDialogOpen, oldCourse, setRefresh }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fileName, setFileName] = useState('');
  const [contentFile, setContentFile] = useState(null);
  const [courseContents, setCourseContents] = useState([]);
  const axios = getAuthAxiosInstance();

  const getCourseContent = async () => {
    try {
      const response = await axios.get(`/course/upload/content/${oldCourse._id}`);
      const { data } = response;
      if (data.success) {
        setCourseContents(data.course.courseContent);
      } else {
        enqueueSnackbar(data.message || 'Cannot get course content', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Error getting course content', { variant: 'error' });
    }
  };

  const handleClose = () => {
    setCourseContentDialogOpen(false);
  };

  const handleFileNameChange = (e) => {
    setFileName(e.target.value);
  };

  const handleContentFileChange = (e) => {
    setContentFile(e.target.files[0]);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (contentFile === null) {
      enqueueSnackbar('Select File', { variant: 'error' });
    } else {
      const formData = new FormData();
      formData.append('docName', fileName);
      formData.append('fileLocation', 'courses');
      formData.append('myFile', contentFile);

      try {
        const response = await axios.post(`/course/upload/content/${oldCourse._id}`, formData);
        const { data } = response;
        if (data.success) {
          setContentFile(null);
          setFileName('');
          setCourseContents(data.course.courseContent);
          enqueueSnackbar('Content Added Successfully');
        } else {
          enqueueSnackbar(data.message || 'Error Adding File', { variant: 'error' });
        }
      } catch (e) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    }
  };

  const onContentDeleteClick = async (courseId, courseContentId) => {
    try {
      const response = await axios.delete(`/course/upload/document/${courseId}/${courseContentId}`);
      const { data } = response;
      if (data.success) {
        enqueueSnackbar('Deleted Successfully');
        setCourseContents(data.course.courseContent);
      } else {
        enqueueSnackbar(data.message || 'Something went wrong', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (oldCourse) {
      getCourseContent();
    }
  }, [oldCourse]);

  return (
    <Dialog open={courseContentDialogOpen} onClose={handleClose} maxWidth="lg">
      <DialogTitle>Course Content</DialogTitle>

      <DialogContent>
        <br />
        <br />

        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={3}>
            <Grid item md={4}>
              <TextField
                size="small"
                id="title"
                name="title"
                label="File Name"
                value={fileName}
                onChange={handleFileNameChange}
                required
              />
            </Grid>
            <Grid item md={4}>
              <input type="file" id="file" name="file" onChange={handleContentFileChange} />
            </Grid>
            <Grid item md={3}>
              <Button fullWidth size="small" type="submit" variant="contained">
                Add File
              </Button>
            </Grid>
          </Grid>
        </form>
        <Grid item xs={12} md={6}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Current
          </Typography>
          <List dense={true}>
            {courseContents.map((c) => {
              return (
                <ListItem
                  key={c._id}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        onContentDeleteClick(oldCourse._id, c._id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={c.docName} secondary="this is secondary" />
                </ListItem>
              );
            })}
          </List>
        </Grid>
        <br />
      </DialogContent>
    </Dialog>
  );
};

export default CourseContentModal;
