import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import getAuthAxiosInstance from '../../../utils/axios';

const addAdvisorValidationSchema = yup.object({
  name: yup.string('Enter the title').required('Title is required'),
  shortTitle: yup.string('Enter Title').required('Title is required'),
  serialNumber: yup.number('Position'),
  isActive: yup.boolean('Is Active'),
});

const AddAdvisorModal = ({ dialogOpen, setDialogOpen, isEdit, oldAdvisor, setRefresh }) => {
  const { enqueueSnackbar } = useSnackbar();
  const axios = getAuthAxiosInstance();

  const [selectedImage, setSelectedImage] = useState(null);
  const [tempImage, setTempImage] = useState(null);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const onImageChange = (e) => {
    const gSelectedImage = e.target.files[0];
    setSelectedImage(gSelectedImage);
    setTempImage(URL.createObjectURL(gSelectedImage));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: isEdit && oldAdvisor?.name ? oldAdvisor.name : '',
      shortTitle: isEdit && oldAdvisor?.shortTitle ? oldAdvisor.shortTitle : '',
      serialNumber: isEdit && oldAdvisor?.serialNumber ? oldAdvisor.serialNumber : 0,
      isActive: isEdit && oldAdvisor?.isActive ? oldAdvisor.isActive : false,
    },
    validationSchema: addAdvisorValidationSchema,
    onSubmit: (values) => {
      if (isEdit) {
        updateoldAdvisor(values);
      } else {
        addDegree(values);
      }
    },
  });

  useEffect(() => {
    if (isEdit && oldAdvisor) {
      setTempImage(`${process.env.REACT_APP_BASE}${oldAdvisor.imageUrl}`);
    } else {
      setTempImage(null);
    }
  }, [isEdit, oldAdvisor]);

  const addDegree = async (values) => {
    if (selectedImage === null) {
      enqueueSnackbar('Select Image', { variant: 'error' });
    } else {
      const formData = new FormData();
      formData.append('isActive', values.isActive);
      formData.append('name', values.name);
      formData.append('shortTitle', values.shortTitle);
      formData.append('serialNumber', values.serialNumber);
      formData.append('imageLocationType', 'advisor');
      formData.append('picture', selectedImage);
      try {
        const response = await axios.post(`/advisor/add`, formData);
        const { data } = response;
        if (data.success) {
          formik.resetForm();
          setRefresh((prev) => !prev);
          setSelectedImage(null);
          setTempImage(null);
          handleClose();
          enqueueSnackbar('Advisor Added Successfully');
        } else {
          enqueueSnackbar(data.message || 'Error Adding', { variant: 'error' });
        }
      } catch (e) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    }
  };

  const updateoldAdvisor = async (values) => {
    const formData = new FormData();

    formData.append('isActive', values.isActive);
    formData.append('name', values.name);
    formData.append('shortTitle', values.shortTitle);
    formData.append('serialNumber', values.serialNumber);
    formData.append('imageLocationType', 'advisor');
    formData.append('imageUrl', oldAdvisor.imageUrl);
    if (selectedImage) {
      formData.append('picture', selectedImage);
    }
    try {
      const response = await axios.put(`/advisor/update/${oldAdvisor._id}`, formData);
      const { data } = response;
      if (data.success) {
        formik.resetForm();
        setRefresh((prev) => !prev);
        setSelectedImage(null);
        setTempImage(null);
        handleClose();
        enqueueSnackbar('Advisor Updated Successfully');
      } else {
        enqueueSnackbar(data.message || 'Error Adding', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  return (
    <Dialog open={dialogOpen} onClose={handleClose} maxWidth="lg">
      <DialogTitle>{isEdit ? 'Update Advisor' : 'Add Advisor'}</DialogTitle>

      <DialogContent>
        <br />
        <br />
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                fullWidth
                id="shortTitle"
                name="shortTitle"
                label="Short Title"
                value={formik.values.shortTitle}
                onChange={formik.handleChange}
                error={formik.touched.shortTitle && Boolean(formik.errors.shortTitle)}
                helperText={formik.touched.shortTitle && formik.errors.shortTitle}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                fullWidth
                type="number"
                id="serialNumber"
                name="serialNumber"
                label="Serial Number"
                value={formik.values.serialNumber}
                onChange={formik.handleChange}
                error={formik.touched.serialNumber && Boolean(formik.errors.serialNumber)}
                helperText={formik.touched.serialNumber && formik.errors.serialNumber}
              />
            </Grid>

            <Grid item sm={12}>
              <FormControlLabel
                control={
                  <Switch
                    name="isActive"
                    checked={formik.values.isActive}
                    onChange={formik.handleChange}
                    error={formik.touched.isActive && Boolean(formik.errors.isActive)}
                    helperText={formik.touched.isActive && formik.errors.isActive}
                  />
                }
                label="Active"
              />
            </Grid>
            <Grid item sm={12}>
              <Button variant="contained" component="label">
                Upload
                <input hidden accept="image/*" type="file" onChange={onImageChange} />
              </Button>
              {tempImage && (
                <img
                  alt="degree"
                  src={tempImage}
                  style={{
                    marginTop: '15px',
                    borderRadius: '20px',
                    width: '200px',
                    height: '200px',
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                />
              )}
            </Grid>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 2, ml: 3 }}>
              {isEdit ? 'Update Advisor' : 'Add Advisor'}
            </LoadingButton>
          </Grid>
        </form>
        <br />
      </DialogContent>
    </Dialog>
  );
};

export default AddAdvisorModal;
