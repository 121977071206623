import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Card, Stack, Button, Container, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import ViewAdvisor from '../sections/@dashboard/advisor/ViewAdvisor';
import AddAdvisorModal from '../sections/@dashboard/advisor/AddAdvisorModal';
import Iconify from '../components/iconify';
import getAuthAxiosInstance from '../utils/axios';

export default function AdvisorPage() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [oldAdvisor, setOldAdvisor] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const axios = getAuthAxiosInstance();

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const onDeleteClick = async (advisor) => {
    try {
      const response = await axios.delete(`/advisor/delete/${advisor._id}`);
      const { data } = response;
      if (data.success) {
        enqueueSnackbar('Deleted Successfully');
        setRefresh((prev) => !prev);
      } else {
        enqueueSnackbar(data.message || 'Something went wrong', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const onUpdateClick = (advisor) => {
    setOldAdvisor(advisor);
    setIsEdit(true);
    setDialogOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>Advisor</title>
      </Helmet>

      <AddAdvisorModal
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        isEdit={isEdit}
        oldAdvisor={oldAdvisor}
        setRefresh={setRefresh}
      />

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Advisor
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setIsEdit(false);
              handleDialogOpen();
            }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Advisor
          </Button>
        </Stack>

        <Card>
          <ViewAdvisor onUpdateClick={onUpdateClick} refresh={refresh} onDeleteClick={onDeleteClick} />
        </Card>
      </Container>
    </>
  );
}
