import { Navigate, useRoutes, Routes, Route } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
//
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import DegreePage from './pages/DegreePage';
import PrivateRoute from './hoc/PrivateRoute';
import CoursePage from './pages/CoursePage';
import BODPage from './pages/BODPage';
import AdvisorPage from './pages/AdvisorPage';
import TestimonialPage from './pages/TestomonialPage';
import MessageBoardPage from './pages/MessageBoardPage';
import PagePage from './pages/PagePage';
import DownloadsPage from './pages/DownloadsPage';
import InformationPage from './pages/InformationPage';
import PopupPage from './pages/PopupPage';
import GalleryPage from './pages/GalleryPage';
import DepartmentPage from './pages/DepartmentPage';
import FacultyPage from './pages/FacultyPage';
import AdministrationDepartmentPage from './pages/AdministrationDepartmentPage';

// ----------------------------------------------------------------------

export default function Router({ isLoggedIn }) {
  return (
    <Routes>
      <Route path="/" element={<DashboardLayout />}>
        <Route index element={<Navigate to={'/degree'} />} />
        <Route
          path="app"
          element={
            <PrivateRoute isAuth={isLoggedIn}>
              <DashboardAppPage />
            </PrivateRoute>
          }
        />
        <Route
          path="degree"
          element={
            <PrivateRoute isAuth={isLoggedIn}>
              <DegreePage />
            </PrivateRoute>
          }
        />
        <Route
          path="course"
          element={
            <PrivateRoute isAuth={isLoggedIn}>
              <CoursePage />
            </PrivateRoute>
          }
        />
        <Route
          path="bod"
          element={
            <PrivateRoute isAuth={isLoggedIn}>
              <BODPage />
            </PrivateRoute>
          }
        />
        <Route
          path="advisor"
          element={
            <PrivateRoute isAuth={isLoggedIn}>
              <AdvisorPage />
            </PrivateRoute>
          }
        />
        <Route
          path="testimonial"
          element={
            <PrivateRoute isAuth={isLoggedIn}>
              <TestimonialPage />
            </PrivateRoute>
          }
        />
        <Route
          path="messageboard"
          element={
            <PrivateRoute isAuth={isLoggedIn}>
              <MessageBoardPage />
            </PrivateRoute>
          }
        />
        <Route
          path="pages"
          element={
            <PrivateRoute isAuth={isLoggedIn}>
              <PagePage />
            </PrivateRoute>
          }
        />
        <Route
          path="downloads"
          element={
            <PrivateRoute isAuth={isLoggedIn}>
              <DownloadsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="information"
          element={
            <PrivateRoute isAuth={isLoggedIn}>
              <InformationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="popup"
          element={
            <PrivateRoute isAuth={isLoggedIn}>
              <PopupPage />
            </PrivateRoute>
          }
        />
        <Route
          path="gallery"
          element={
            <PrivateRoute isAuth={isLoggedIn}>
              <GalleryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="department"
          element={
            <PrivateRoute isAuth={isLoggedIn}>
              <DepartmentPage />
            </PrivateRoute>
          }
        />
        <Route
          path="faculty"
          element={
            <PrivateRoute isAuth={isLoggedIn}>
              <FacultyPage />
            </PrivateRoute>
          }
        />
        <Route
          path="administration-department"
          element={
            <PrivateRoute isAuth={isLoggedIn}>
              <AdministrationDepartmentPage />
            </PrivateRoute>
          }
        />

        {/* <Route path="user" element={<UserPage />} /> */}
      </Route>
      <Route path="/login" element={<LoginPage />} />
      {/* <Route path="/books" element={<BooksLayout />}>
        <Route index element={<BookList />} />
        <Route path=":id" element={<Book />} />
        <Route path="new" element={<NewBook />} />
      </Route>
      <Route element={<OtherLayout />}>
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
      </Route> */}
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
  // const routes = useRoutes([
  //   {
  //     element: <SimpleLayout />,
  //     children: [
  //       { element: <Navigate to="/dashboard/app" />, index: true },
  //       { path: '404', element: <Page404 /> },
  //       { path: '*', element: <Navigate to="/404" /> },
  //     ],
  //   },
  //   {
  //     path: '/dashboard',
  //     element: (
  //       <PrivateRoute>
  //         <DashboardLayout />
  //       </PrivateRoute>
  //     ),
  //     children: [
  //       { element: <Navigate to="/dashboard/app" />, index: true },
  //       { path: 'app', element: <DashboardAppPage /> },
  //       { path: 'user', element: <UserPage /> },
  //       { path: 'degree', element: <DegreePage /> },
  //       { path: 'products', element: <ProductsPage /> },
  //       { path: 'blog', element: <BlogPage /> },
  //     ],
  //   },

  //   {
  //     path: '*',
  //     element: <Navigate to="/404" replace />,
  //   },
  // ]);
}
