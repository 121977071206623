import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Card, Stack, Button, Container, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import ViewTestimonial from '../sections/@dashboard/testimonial/ViewTestimonial';
import AddTestimonialModal from '../sections/@dashboard/testimonial/AddTestimonialModal';
import Iconify from '../components/iconify';
import getAuthAxiosInstance from '../utils/axios';

export default function TestimonialPage() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [oldTestimonial, setOldTestimonial] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const axios = getAuthAxiosInstance();

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const onDeleteClick = async (testimonial) => {
    try {
      const response = await axios.delete(`/testimonial/delete/${testimonial._id}`);
      const { data } = response;
      if (data.success) {
        enqueueSnackbar('Deleted Successfully');
        setRefresh((prev) => !prev);
      } else {
        enqueueSnackbar(data.message || 'Something went wrong', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const onUpdateClick = (testimonial) => {
    setOldTestimonial(testimonial);
    setIsEdit(true);
    setDialogOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>Testimonial </title>
      </Helmet>

      <AddTestimonialModal
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        isEdit={isEdit}
        oldTestimonial={oldTestimonial}
        setRefresh={setRefresh}
      />

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Testimonial
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setIsEdit(false);
              handleDialogOpen();
            }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Testimonial
          </Button>
        </Stack>

        <Card>
          <ViewTestimonial onUpdateClick={onUpdateClick} refresh={refresh} onDeleteClick={onDeleteClick} />
        </Card>
      </Container>
    </>
  );
}
