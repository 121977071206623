import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Card, Stack, Button, Container, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import ViewDegree from '../sections/@dashboard/degree/ViewDegree';
import AddDegreeModal from '../sections/@dashboard/degree/AddDegreeModal';
import Iconify from '../components/iconify';
import getAuthAxiosInstance from '../utils/axios';
import GeneralInformation from '../sections/@dashboard/information/GeneralInformation';

export default function InformationPage() {
  const [refresh, setRefresh] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Helmet>
        <title>Information | Nepalaya Admin</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Information
          </Typography>
          {/* <Button
            variant="contained"
            onClick={() => {
            }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Degree
          </Button> */}
        </Stack>

        <Card>
          <GeneralInformation />
        </Card>
      </Container>
    </>
  );
}
