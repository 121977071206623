import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import { useAuthContext } from './hooks/useAuthContext';
import NotiStackProvider from './context/NotiStackProvider';

// ----------------------------------------------------------------------

export default function App() {
  const { user, ready } = useAuthContext();
  if (!ready) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    );
  }
  
  return (
    <ThemeProvider>
      <NotiStackProvider>
        <ScrollToTop />
        <StyledChart />
        <Router isLoggedIn={!!user} user={user} />
      </NotiStackProvider>
    </ThemeProvider>
  );
}
