import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import getAuthAxiosInstance from '../../../utils/axios';
import ContentEditor from '../../../components/content_editor/ContentEditor';

const MessageBoardValidation = yup.object({
  name: yup.string('Enter the title').required('Title is required'),
  primaryTitle: yup.string('Enter your primary title').required('primary title is required'),
  keyName: yup.string('Enter the keyname').required('Key name is required'),
  secondaryTitle: yup.string('Enter your description'),
  content: yup.string('Enter your content'),
  isActive: yup.boolean('Is Active'),
});

const AddMessageBoardModal = ({ dialogOpen, setDialogOpen, isEdit, oldMessageBoard, setRefresh }) => {
  const axios = getAuthAxiosInstance();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedImage, setSelectedImage] = useState(null);
  const [tempImage, setTempImage] = useState(null);
  const [contentText, setContentText] = useState('');

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleContentEditor = (text) => {
    setContentText(text);
  };

  const onImageChange = (e) => {
    const gSelectedImage = e.target.files[0];
    setSelectedImage(gSelectedImage);
    setTempImage(URL.createObjectURL(gSelectedImage));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: isEdit && oldMessageBoard?.name ? oldMessageBoard.name : '',
      keyName: isEdit && oldMessageBoard?.keyName ? oldMessageBoard.keyName : '',
      primaryTitle: isEdit && oldMessageBoard?.primaryTitle ? oldMessageBoard.primaryTitle : '',
      secondaryTitle: isEdit && oldMessageBoard?.secondaryTitle ? oldMessageBoard.secondaryTitle : '',
      content: isEdit && oldMessageBoard?.content ? oldMessageBoard.content : '',
      isActive: isEdit && oldMessageBoard?.isActive ? oldMessageBoard.isActive : false,
    },
    validationSchema: MessageBoardValidation,
    onSubmit: (values) => {
      values.content = contentText;
      if (isEdit) {
        updateOldDegree(values);
      } else {
        addDegree(values);
      }
    },
  });

  useEffect(() => {
    if (isEdit && oldMessageBoard) {
      setTempImage(`${process.env.REACT_APP_BASE}${oldMessageBoard.imageUrl}`);
      setContentText(oldMessageBoard.content);
    } else {
      setTempImage(null);
    }
  }, [isEdit, oldMessageBoard]);

  const addDegree = async (values) => {
    if (selectedImage === null) {
      enqueueSnackbar('Select Image', { variant: 'error' });
    } else {
      const formData = new FormData();
      formData.append('isActive', values.isActive);
      formData.append('name', values.name);
      formData.append('primaryTitle', values.primaryTitle);
      formData.append('secondaryTitle', values.secondaryTitle);
      formData.append('content', values.content);
      formData.append('keyName', values.keyName);
      formData.append('imageLocationType', 'messageboard');
      formData.append('picture', selectedImage);
      try {
        const response = await axios.post(`/messageboard/add`, formData);
        const { data } = response;
        if (data.success) {
          formik.resetForm();
          setRefresh((prev) => !prev);
          setSelectedImage(null);
          setTempImage(null);
          handleClose();
          enqueueSnackbar('Message Board Added Successfully');
        } else {
          enqueueSnackbar(data.message || 'Error Adding', { variant: 'error' });
        }
      } catch (e) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    }
  };

  const updateOldDegree = async (values) => {
    const formData = new FormData();

    formData.append('isActive', values.isActive);
    formData.append('name', values.name);
    formData.append('primaryTitle', values.primaryTitle);
    formData.append('secondaryTitle', values.secondaryTitle);
    formData.append('content', values.content);
    formData.append('keyName', values.keyName);
    formData.append('imageLocationType', 'messageboard');
    formData.append('imageUrl', oldMessageBoard.imageUrl);
    if (selectedImage) {
      formData.append('picture', selectedImage);
    }
    try {
      const response = await axios.put(`/messageboard/update/${oldMessageBoard._id}`, formData);
      const { data } = response;
      if (data.success) {
        formik.resetForm();
        setRefresh((prev) => !prev);
        setSelectedImage(null);
        setTempImage(null);
        handleClose();
        enqueueSnackbar('Message Updated Successfully');
      } else {
        enqueueSnackbar(data.message || 'Error Adding', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  return (
    <Dialog open={dialogOpen} onClose={handleClose} maxWidth="lg">
      <DialogTitle>{isEdit ? 'Update Message Board' : 'Add Message Board'}</DialogTitle>

      <DialogContent>
        <br />
        <br />
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                fullWidth
                id="keyName"
                name="keyName"
                label="Key Name"
                value={formik.values.keyName}
                onChange={formik.handleChange}
                error={formik.touched.keyName && Boolean(formik.errors.keyName)}
                helperText={formik.touched.keyName && formik.errors.keyName}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                fullWidth
                id="primaryTitle"
                name="primaryTitle"
                label="Primary Title"
                value={formik.values.primaryTitle}
                onChange={formik.handleChange}
                error={formik.touched.primaryTitle && Boolean(formik.errors.primaryTitle)}
                helperText={formik.touched.primaryTitle && formik.errors.primaryTitle}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                fullWidth
                id="secondaryTitle"
                name="secondaryTitle"
                label="Secondary Title"
                value={formik.values.secondaryTitle}
                onChange={formik.handleChange}
                error={formik.touched.secondaryTitle && Boolean(formik.errors.secondaryTitle)}
                helperText={formik.touched.secondaryTitle && formik.errors.secondaryTitle}
              />
            </Grid>
            <Grid item sm={12}>
              <ContentEditor model={contentText} handleModelChange={handleContentEditor} />
            </Grid>

            <Grid item sm={12}>
              <FormControlLabel
                control={
                  <Switch
                    name="isActive"
                    checked={formik.values.isActive}
                    onChange={formik.handleChange}
                    error={formik.touched.isActive && Boolean(formik.errors.isActive)}
                    helperText={formik.touched.isActive && formik.errors.isActive}
                  />
                }
                label="Active"
              />
            </Grid>
            <Grid item sm={12}>
              <Button variant="contained" component="label">
                Upload
                <input hidden accept="image/*" type="file" onChange={onImageChange} />
              </Button>
              {tempImage && (
                <img
                  alt="degree"
                  src={tempImage}
                  style={{
                    marginTop: '15px',
                    borderRadius: '20px',
                    width: '200px',
                    height: '200px',
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                />
              )}
            </Grid>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 2, ml: 3 }}>
              {isEdit ? 'Update Message Board' : 'Add Message Board'}
            </LoadingButton>
          </Grid>
        </form>
        <br />
      </DialogContent>
    </Dialog>
  );
};

export default AddMessageBoardModal;
