import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import getAuthAxiosInstance from '../../../utils/axios';
import ContentEditor from '../../../components/content_editor/ContentEditor';

const addCourseValidation = yup.object({
  title: yup.string('Enter the title').required('Title is required'),
  description: yup.string('Enter your description').required('description is required'),
  content: yup.string('Enter your content'),
  degree: yup.string('Select a degree').required('Select a degree'),
  totalCredit: yup.string('Total Credit'),
  courseDuration: yup.string('Course Duration'),
  programDuration: yup.string('Program Duration'),
  courseType: yup.string('Course Type'),
  totalCost: yup.string('Total Cost'),
  isActive: yup.boolean('Is Active'),
  internship: yup.string('Internship'),
  careerPathway: yup.string('Internship'),
  howHelp: yup.string('Internship'),
});

const AddCourseModal = ({ dialogOpen, setDialogOpen, isEdit, oldCourse, setRefresh }) => {
  const axios = getAuthAxiosInstance();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedImage, setSelectedImage] = useState(null);
  const [tempImage, setTempImage] = useState(null);
  const [contentText, setContentText] = useState('');
  const [degrees, setDegrees] = useState([]);

  const handleContentEditor = (text) => {
    setContentText(text);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const onImageChange = (e) => {
    const gSelectedImage = e.target.files[0];
    setSelectedImage(gSelectedImage);
    setTempImage(URL.createObjectURL(gSelectedImage));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: isEdit && oldCourse?.title ? oldCourse.title : '',
      description: isEdit && oldCourse?.description ? oldCourse.description : '',
      degree: isEdit && oldCourse?.degree ? oldCourse.degree : '',
      totalCredit: isEdit && oldCourse?.totalCredit ? oldCourse.totalCredit : '',
      courseDuration: isEdit && oldCourse?.courseDuration ? oldCourse.courseDuration : '',
      programDuration: isEdit && oldCourse?.programDuration ? oldCourse.programDuration : '',
      courseType: isEdit && oldCourse?.courseType ? oldCourse.courseType : '',
      totalCost: isEdit && oldCourse?.totalCost ? oldCourse.totalCost : '',
      internship: isEdit && oldCourse?.internship ? oldCourse.internship : '',
      careerPathway: isEdit && oldCourse?.careerPathway ? oldCourse.careerPathway : '',
      howHelp: isEdit && oldCourse?.howHelp ? oldCourse.howHelp : '',
      isActive: isEdit && oldCourse?.isActive ? oldCourse.isActive : false,
    },
    validationSchema: addCourseValidation,
    onSubmit: (values) => {
      values.content = contentText;
      if (isEdit) {
        updateOldCourse(values);
      } else {
        addDegree(values);
      }
    },
  });

  const getAllDegrees = async () => {
    try {
      const response = await axios.get(`/degree/all`);
      const { data } = response;
      if (data.success) {
        setDegrees(data.degrees);
      } else {
        enqueueSnackbar(data.message || 'Error Adding', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  useEffect(() => {
    getAllDegrees();
  }, []);

  useEffect(() => {
    if (isEdit && oldCourse) {
      setTempImage(`${process.env.REACT_APP_BASE}${oldCourse.imageUrl}`);
      setContentText(oldCourse.content);
    } else {
      setTempImage(null);
    }
  }, [isEdit, oldCourse]);

  const addDegree = async (values) => {
    if (selectedImage === null) {
      enqueueSnackbar('Select Image', { variant: 'error' });
    } else {
      const formData = new FormData();
      formData.append('isActive', values.isActive);
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('content', values.content);
      formData.append('totalCredit', values.totalCredit);
      formData.append('courseDuration', values.courseDuration);
      formData.append('degreeDuration', values.degreeDuration);
      formData.append('courseType', values.courseType);
      formData.append('totalCost', values.totalCost);
      formData.append('careerPathway', values.careerPathway);
      formData.append('howHelp', values.howHelp);
      formData.append('internship', values.internship);
      formData.append('degree', values.degree);
      formData.append('imageLocationType', 'courses');
      formData.append('picture', selectedImage);
      try {
        const response = await axios.post(`/course/add`, formData);
        const { data } = response;
        if (data.success) {
          formik.resetForm();
          setRefresh((prev) => !prev);
          setSelectedImage(null);
          setTempImage(null);
          handleClose();
          enqueueSnackbar('Degree Added Successfully');
        } else {
          enqueueSnackbar(data.message || 'Error Adding', { variant: 'error' });
        }
      } catch (e) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    }
  };

  const updateOldCourse = async (values) => {
    const formData = new FormData();

    formData.append('isActive', values.isActive);
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('content', values.content);
    formData.append('totalCredit', values.totalCredit);
    formData.append('courseDuration', values.courseDuration);
    formData.append('degreeDuration', values.degreeDuration);
    formData.append('courseType', values.courseType);
    formData.append('totalCost', values.totalCost);
    formData.append('careerPathway', values.careerPathway);
    formData.append('howHelp', values.howHelp);
    formData.append('internship', values.internship);
    formData.append('degree', values.degree);
    formData.append('imageLocationType', 'courses');
    formData.append('imageUrl', oldCourse.imageUrl);
    if (selectedImage) {
      formData.append('picture', selectedImage);
    }
    try {
      const response = await axios.put(`/course/update/${oldCourse._id}`, formData);
      const { data } = response;
      if (data.success) {
        formik.resetForm();
        setRefresh((prev) => !prev);
        setSelectedImage(null);
        setTempImage(null);
        handleClose();
        enqueueSnackbar('Course Updated Successfully');
      } else {
        enqueueSnackbar(data.message || 'Error Adding', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  return (
    <Dialog open={dialogOpen} onClose={handleClose} maxWidth="lg">
      <DialogTitle>{isEdit ? 'Update Course' : 'Add Course'}</DialogTitle>

      <DialogContent>
        <br />
        <br />
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <TextField
                fullWidth
                id="title"
                name="title"
                label="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                multiline
                maxRows={6}
                fullWidth
                id="description"
                name="description"
                label="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
              />
            </Grid>
            <Grid item sm={12}>
              <FormControl variant="filled" fullWidth>
                <InputLabel id="degree-select">Degree</InputLabel>
                <Select
                  fullWidth
                  name="degree"
                  labelId="degree-select"
                  value={formik.values.degree}
                  onChange={formik.handleChange}
                  error={formik.touched.degree && Boolean(formik.errors.degree)}
                  helperText={formik.touched.degree && formik.errors.degree}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {degrees.map((d) => {
                    return <MenuItem value={d._id}>{d.title}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <ContentEditor model={contentText} handleModelChange={handleContentEditor} />
            </Grid>
            <Grid item sm={12}>
              <TextField
                multiline
                maxRows={6}
                fullWidth
                id="careerPathway"
                name="careerPathway"
                label="Career Pathway(Separate with comma,))"
                value={formik.values.careerPathway}
                onChange={formik.handleChange}
                error={formik.touched.careerPathway && Boolean(formik.errors.careerPathway)}
                helperText={formik.touched.careerPathway && formik.errors.careerPathway}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                multiline
                maxRows={6}
                fullWidth
                id="howHelp"
                name="howHelp"
                label="How This Course will help (Separate with semi-colon;)"
                value={formik.values.howHelp}
                onChange={formik.handleChange}
                error={formik.touched.howHelp && Boolean(formik.errors.howHelp)}
                helperText={formik.touched.howHelp && formik.errors.howHelp}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                fullWidth
                id="totalCredit"
                name="totalCredit"
                label="totalCredit"
                value={formik.values.totalCredit}
                onChange={formik.handleChange}
                error={formik.touched.totalCredit && Boolean(formik.errors.totalCredit)}
                helperText={formik.touched.totalCredit && formik.errors.totalCredit}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                fullWidth
                id="courseDuration"
                name="courseDuration"
                label="courseDuration"
                value={formik.values.courseDuration}
                onChange={formik.handleChange}
                error={formik.touched.courseDuration && Boolean(formik.errors.courseDuration)}
                helperText={formik.touched.courseDuration && formik.errors.courseDuration}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                fullWidth
                id="programDuration"
                name="programDuration"
                label="programDuration"
                value={formik.values.programDuration}
                onChange={formik.handleChange}
                error={formik.touched.programDuration && Boolean(formik.errors.programDuration)}
                helperText={formik.touched.programDuration && formik.errors.programDuration}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                fullWidth
                id="courseType"
                name="courseType"
                label="courseType"
                value={formik.values.courseType}
                onChange={formik.handleChange}
                error={formik.touched.courseType && Boolean(formik.errors.courseType)}
                helperText={formik.touched.courseType && formik.errors.courseType}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                fullWidth
                id="totalCost"
                name="totalCost"
                label="totalCost"
                value={formik.values.totalCost}
                onChange={formik.handleChange}
                error={formik.touched.totalCost && Boolean(formik.errors.totalCost)}
                helperText={formik.touched.totalCost && formik.errors.totalCost}
              />
            </Grid>

            <Grid item sm={6}>
              <TextField
                fullWidth
                id="internship"
                name="internship"
                label="internship"
                value={formik.values.internship}
                onChange={formik.handleChange}
                error={formik.touched.internship && Boolean(formik.errors.internship)}
                helperText={formik.touched.internship && formik.errors.internship}
              />
            </Grid>

            <Grid item sm={12}>
              <FormControlLabel
                control={
                  <Switch
                    name="isActive"
                    checked={formik.values.isActive}
                    onChange={formik.handleChange}
                    error={formik.touched.isActive && Boolean(formik.errors.isActive)}
                    helperText={formik.touched.isActive && formik.errors.isActive}
                  />
                }
                label="Active"
              />
            </Grid>
            <Grid item sm={12}>
              <Button variant="contained" component="label">
                Upload
                <input hidden accept="image/*" type="file" onChange={onImageChange} />
              </Button>
              {tempImage && (
                <img
                  alt="course"
                  src={tempImage}
                  style={{
                    marginTop: '15px',
                    borderRadius: '20px',
                    width: '200px',
                    height: '200px',
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                />
              )}
            </Grid>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 2, ml: 3 }}>
              {isEdit ? 'Update Course' : 'Add Course'}
            </LoadingButton>
          </Grid>
        </form>
        <br />
      </DialogContent>
    </Dialog>
  );
};

export default AddCourseModal;
