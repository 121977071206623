import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Link,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import getAuthAxiosInstance from '../../../utils/axios';

const addBODValidationSchema = yup.object({
  title: yup.string('Enter the title').required('Title is required'),
  isActive: yup.boolean('Is Active'),
});

const AddDownloadsModal = ({ dialogOpen, setDialogOpen, isEdit, oldDownload, setRefresh }) => {
  const axios = getAuthAxiosInstance();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedFile, setSelectedFile] = useState(null);
  const [tempFile, setTemFile] = useState(null);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const onFileChange = (e) => {
    const gSelectedFile = e.target.files[0];
    setSelectedFile(gSelectedFile);
    setTemFile(URL.createObjectURL(gSelectedFile));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: isEdit && oldDownload?.title ? oldDownload.title : '',
      isActive: isEdit && oldDownload?.isActive ? oldDownload.isActive : false,
    },
    validationSchema: addBODValidationSchema,
    onSubmit: (values) => {
      if (isEdit) {
        updateOldDegree(values);
      } else {
        addDegree(values);
      }
    },
  });

  useEffect(() => {
    if (isEdit && oldDownload) {
      setTemFile(`${process.env.REACT_APP_BASE}${oldDownload.fileUrl}`);
    } else {
      setTemFile(null);
    }
  }, [isEdit, oldDownload]);

  const addDegree = async (values) => {
    if (selectedFile === null) {
      enqueueSnackbar('Select Image', { variant: 'error' });
    } else {
      const formData = new FormData();
      formData.append('isActive', values.isActive);
      formData.append('title', values.title);
      formData.append('fileLocation', 'downloads');
      formData.append('myFile', selectedFile);
      try {
        const response = await axios.post(`/downloads/add`, formData);
        const { data } = response;
        if (data.success) {
          formik.resetForm();
          setRefresh((prev) => !prev);
          setSelectedFile(null);
          setTemFile(null);
          handleClose();
          enqueueSnackbar('Download Added Successfully');
        } else {
          enqueueSnackbar(data.message || 'Error Adding', { variant: 'error' });
        }
      } catch (e) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    }
  };

  const updateOldDegree = async (values) => {
    const formData = new FormData();

    formData.append('isActive', values.isActive);
    formData.append('title', values.title);
    formData.append('fileLocation', 'downloads');
    formData.append('fileUrl', oldDownload.fileUrl);
    if (selectedFile) {
      formData.append('myFile', selectedFile);
    }
    try {
      const response = await axios.put(`/downloads/update/${oldDownload._id}`, formData);
      const { data } = response;
      if (data.success) {
        formik.resetForm();
        setRefresh((prev) => !prev);
        setSelectedFile(null);
        setTemFile(null);
        handleClose();
        enqueueSnackbar('Download Updated Successfully');
      } else {
        enqueueSnackbar(data.message || 'Error Adding', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  return (
    <Dialog open={dialogOpen} onClose={handleClose} maxWidth="lg">
      <DialogTitle>{isEdit ? 'Update Download' : 'Add Download'}</DialogTitle>

      <DialogContent>
        <br />
        <br />
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <TextField
                fullWidth
                id="title"
                name="title"
                label="Name"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
            </Grid>
            <Grid item sm={12}>
              <FormControlLabel
                control={
                  <Switch
                    name="isActive"
                    checked={formik.values.isActive}
                    onChange={formik.handleChange}
                    error={formik.touched.isActive && Boolean(formik.errors.isActive)}
                    helperText={formik.touched.isActive && formik.errors.isActive}
                  />
                }
                label="Active"
              />
            </Grid>
            <Grid item sm={12}>
              <Button variant="contained" component="label">
                Upload
                <input hidden type="file" onChange={onFileChange} />
              </Button>
              {tempFile && (
                <Link sx={{ mr: 2 }} target="_blank" rel="noreferrer" href={tempFile} underline="hover">
                  Current File
                </Link>
              )}
            </Grid>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 2, ml: 3 }}>
              {isEdit ? 'Update Download' : 'Add Download'}
            </LoadingButton>
          </Grid>
        </form>
        <br />
      </DialogContent>
    </Dialog>
  );
};

export default AddDownloadsModal;
